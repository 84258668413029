import { Application } from 'application/context.instance';
import { Card } from 'core/entity/card';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { Page } from 'core/entity/page';
import { List } from 'immutable';
import { Event, EventBus } from 'presentation/bus';
import { DocumentScrollEvent } from 'presentation/bus/event/document-scroll-event';
import { CardAnnualFeePromotionItem } from 'presentation/components/card-promotion-annualfee';
import { DropDown } from 'presentation/components/drop-down';
import { DropDownModel } from 'presentation/components/drop-down/model';
import { FetchEmptyPaddingSize } from 'presentation/components/fetch-state/empty';
import { FetchError } from 'presentation/components/fetch-state/error';
import { FixedBestCardRecommendationBanner } from 'presentation/components/fixed/best-card-recommandation-banner';
import { Footer } from 'presentation/components/footer';
import { Header } from 'presentation/components/header';
import { Loader } from 'presentation/components/loader';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { SubscriptionBag } from 'presentation/module/extension';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { toCardPriority } from 'presentation/view-model/card-priority';
import { PriorityPreset } from 'presentation/view-model/card-priority/preset';
import { FetchPageState, FetchState } from 'presentation/view-model/fetch-state';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { apply, emptyList, lets } from 'utils/index';

import styles from './styles.pcss';
const PAGE_SIZE = 10;
const SCROLL_BOTTOM_TO_FETCH = 1000;

const PRIORITY_FILTERS = List([
  new DropDownModel(PriorityPreset.PROFIT_DESC, '지원 금액순'),
  new DropDownModel(PriorityPreset.ANNUAL_COST_ASC, '연회비 낮은순'),
  new DropDownModel(PriorityPreset.ANNUAL_COST_DESC, '연회비 높은순'),
  new DropDownModel(PriorityPreset.PROMOTION_CREATE_DATE_DESC, '최신순'),
  new DropDownModel(PriorityPreset.PROMOTION_END_DATE_ASC, '마감임박순')
]);

interface State {
    fetchConfigState: FetchState;
    fetchCardsState: FetchPageState;
    page: number;
    cards: List<Card>;
    numberOfCards: number;
    companies?: List<DropDownModel>;
    selectedCompany?: DropDownModel;
    selectedPriority: DropDownModel;
}

export class CardsPromotionAnnualFee extends React.Component<RouteComponentProps<any>, State> {
    state = {
      fetchConfigState: FetchState.FETCHING,
      fetchCardsState: FetchPageState.FETCHED,
      page: 0,
      cards: emptyList(),
      numberOfCards: 0,
      companies: null as List<DropDownModel>,
      selectedCompany: null as DropDownModel,
      selectedPriority: PRIORITY_FILTERS.first()
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
      EventBus.toObservable().subscribe(
        (event: Event) => {
          if (event instanceof DocumentScrollEvent) {
            const {
              fetchConfigState,
              fetchCardsState
            } = this.state;

            if (
              fetchConfigState === FetchState.FETCHED &&
                        fetchCardsState === FetchPageState.FETCHED &&
                        event.scrollBottom < SCROLL_BOTTOM_TO_FETCH
            ) {
              this.fetchCards();
            }
          }
        }
      ).unsubscribeBy(this.subscriptionBag);
      this.fetchConfig();
    }

    componentWillUnmount() {
      this.subscriptionBag.destroy();
    }

    render() {
      const {
        fetchConfigState,
        fetchCardsState,
        companies,
        selectedCompany,
        selectedPriority
      } = this.state;

      const renderComponent = lets(fetchConfigState, it => {
        switch (it) {
        case FetchState.FETCHING:
          return <Loader padding={ 300 } radius={ 25 }/>;

        case FetchState.ERROR:
          return (
            <FetchError padding={ FetchEmptyPaddingSize.LARGE }>
                            일시적인 오류가 발생했습니다<br/>
                            잠시 후 새로고침 해주세요
            </FetchError>
          );

        default:
          return (
            <>
              <MetaHelmet meta={ META_SET.CARDS_ANNUAL_FEE_PROMOTION_RESULT }/>
              <Header active={ FinanceSectorID.CARD } />
              <div className={ styles.wrap }>
                <div className={ styles.container }>
                  <div className={ styles.header }>
                    <span>
                                        검색결과<strong>{ this.state.numberOfCards }개</strong>카드
                    </span>
                    <div className={ styles.sort }>
                      <DropDown
                        list={ companies }
                        selected={ selectedCompany }
                        onChange={ selection => this.onChangeCompanyFilter(selection) }
                        gaCategory={ GA_CATEGORY.CARDS_ANNUAL_FEE_PROMOTION.FILTER_COMPANY }
                      />
                      <DropDown
                        list={ PRIORITY_FILTERS }
                        selected={ selectedPriority }
                        onChange={ selection => this.onChangePriorityFilter(selection) }
                        gaCategory={ GA_CATEGORY.CARDS_ANNUAL_FEE_PROMOTION.FILTER_SORT }
                      />
                    </div>
                  </div>
                  <div className={ styles.list }>
                    { this.renderCards() }
                    {
                      fetchCardsState !== FetchPageState.ERROR &&
                                        fetchCardsState !== FetchPageState.EMPTY &&
                                        fetchCardsState !== FetchPageState.FINISHED && (
                        <Loader padding={ 50 } radius={ 25 }/>
                      )
                    }
                  </div>
                  <div className={ styles.terms }>
                    <ul>
                      <li>- 카드사 및 제휴사의 사정으로 위 내용은 변경·중단 될 수 있습니다.</li>
                      <li>- 카드 이용 전에 상품설명서, 약관을 통해 이용조건을 확인해 주시기 바랍니다.</li>
                      <li>- 카드 이용금액 연체 시 21.0 ~ 27.9%의 연체이자율이 적용됩니다.(회원별, 연체기간별, 이용상품별 차등 적용)</li>
                      <li>- 신용카드 남용은 가계경제에 위협이 됩니다.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <FixedBestCardRecommendationBanner
                gaCategory={ GA_CATEGORY.CARDS_ANNUAL_FEE_PROMOTION.BEST_CARD_BANNER }
              />
              <Footer />
            </>
          );
        }
      });

      return (
        <div>
          { renderComponent }
        </div>
      );
    }

    private onChangeCompanyFilter(selectedCompany: DropDownModel) {
      sendGAEvent(GA_DOMAIN.CARD, GA_CATEGORY.CARDS_ANNUAL_FEE_PROMOTION.FILTER_COMPANY, GA_ACTION.RADIO, selectedCompany.value);
      this.setState({ selectedCompany },
        () => this.fetchCards(selectedCompany));
    }

    private onChangePriorityFilter(selectedPriority: DropDownModel) {
      sendGAEvent(GA_DOMAIN.CARD, GA_CATEGORY.CARDS_ANNUAL_FEE_PROMOTION.FILTER_SORT, GA_ACTION.RADIO, selectedPriority.value);
      this.setState({ selectedPriority },
        () => this.fetchCards(selectedPriority));
    }

    private fetchConfig() {
      this.setState({
        fetchConfigState: FetchState.FETCHING
      }, () =>
        Application.useCases.getCardConfiguration
          .runOnAnimateFrame()
          .subscribe(
            result => {
              const companyFilters = List(
                [new DropDownModel(null, "카드사 전체")]
              ).concat(
                result.companies.map(
                  (value, key) => new DropDownModel(key, value)
                )
              ).toList();

              this.setState({
                fetchConfigState: FetchState.FETCHED,
                companies: companyFilters,
                selectedCompany: companyFilters.first()
              }, () =>
                this.fetchCards()
              );
            },
            () =>
              location.href = BANKSALAD_SITEMAP.CARDS_QUESTIONS
          ).unsubscribeBy(this.subscriptionBag)
      );
    }

    private fetchCards(customCompany: DropDownModel = null) {
      const { selectedPriority, page, fetchCardsState, selectedCompany } = this.state;
      const currentPage = customCompany ? 0 : page;

      if (fetchCardsState === FetchPageState.FETCHING) {
        return;
      }

      this.setState(state => ({
        cards: customCompany ? emptyList() : state.cards,
        page: currentPage,
        fetchCardsState: FetchPageState.FETCHING
      }), () => {
        const { companies } = this.state;

        apply(Application.useCases.getPromotionOnlyCards, it => {
          it.companies = selectedCompany.key ? List([selectedCompany.key]) : companies.map((value) => value.key).filter(value => value).toList();
          it.priority = toCardPriority(selectedPriority.key);
          it.page = new Page(PAGE_SIZE, currentPage * PAGE_SIZE);
        }).runOnAnimateFrame().subscribe(
          result => {
            this.setState(state => ({
              numberOfCards: result.numberOfCards,
              cards: state.cards.concat(result.cards).toList(),
              page: state.page + 1,
              fetchCardsState: result.numberOfCards === 0 ?
                FetchPageState.EMPTY : result.cards.size < PAGE_SIZE ?
                  FetchPageState.FINISHED :
                  FetchPageState.FETCHED
            }));

            result.cards.forEach(
              (card, index) => sendGAEvent(
                GA_DOMAIN.CARD,
                GA_CATEGORY.CARDS_ANNUAL_FEE_PROMOTION.RESULT_LIST,
                GA_ACTION.PRODUCT_IMPRESSION,
                `${card.companyName}_${card.id}_${index + 1}`
              )
            );
          },
          () =>
            this.setState({
              fetchCardsState: FetchPageState.ERROR
            })
        );
      });
    }

    private renderCards() {
      const { fetchCardsState, cards } = this.state;
      switch (fetchCardsState) {
      case FetchPageState.EMPTY:
        return (
          <div className={ styles.empty }>
                        검색 결과가 없습니다
          </div>
        );

      case FetchPageState.ERROR:
        return (
          <FetchError padding={ 100 }>
                        일시적인 오류가 발생했습니다<br/>
                        잠시 후 새로고침 해주세요
          </FetchError>
        );
      default:
        return (
          <div>
            <ul className={ styles.cards }>
              {
                cards.map((card, key) =>
                  <li key={ `result-${card.id}` }>
                    <CardAnnualFeePromotionItem
                      card={ card }
                      rank={ key + 1 }
                    />
                  </li>
                )
              }
            </ul>
          </div>
        );
      }
    }
}
