import { AdvertisementCard, BadgeType } from 'core/entity/poong-legacy/advertisement-card';
import { List } from 'immutable';
import { CardIssueUrlAlert } from 'presentation/components/alert/card-issue-url';
import { Carousel } from 'presentation/components/carousel';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import React from 'react';
import { pairOf } from 'utils/index';

import styles from './styles.pcss';
const FIRST_POSITION = 0;

interface Props {
    cards: List<AdvertisementCard>;
}

interface State {
    position: number;
    page: number;
    popupIssueUrl: boolean;
}

export class AdvertisingCardsCarousel extends React.Component<Props, State> {
    state = {
      position: FIRST_POSITION,
      page: 0,
      popupIssueUrl: false,
    };

    private carouselTimer: any = null;

    componentWillUnmount() {
      clearInterval(this.carouselTimer);
    }

    render() {
      const { cards } = this.props;
      const { popupIssueUrl } = this.state;

      return (
        <div className={ styles.wrap }>
          <div>
            <div className={ styles.carouselContainer }>
              <a className={ styles.prev } onClick={ this.onPrev }/>
              <Carousel
                position={ this.state.page }
                onPositionChange={ this.onChangeBannerOrder }
              >
                <ul>
                  { cards.map((card, index) => (
                    <li key={ `card-item-${index}` }>{ this.renderItem(card, index) }</li>
                  )) }
                </ul>
              </Carousel>
              { popupIssueUrl && this.renderAlert() }
              <a className={ styles.next } onClick={ this.onNext }/>
            </div>
            <ul className={ styles.page }>
              {
                cards.map((page, index) =>
                  index === this.state.page ?
                    <li key={ `page-${index}` } className={ styles.activePage }/> :
                    <li key={ `page-${index}` } onClick={ this.movePage(index) }/>
                )
              }
            </ul>
          </div>
        </div>
      );
    }

    private renderAlert = () => {
      const { cards } = this.props;
      const { page } = this.state;
      const card = cards.get(page);
      const issueLink = pairOf(card.issueUrlDesktop, card.issueUrlMobile);

      return (
        <CardIssueUrlAlert
          cardId={ card.card.id }
          issueUrlDesktop={ issueLink.first }
          issueUrlMobile={ issueLink.second }
          companyName={ card.card.companyName }
          companyImageUrl={ card.card.imageUrl }
          gaCategory={ GA_CATEGORY.CARDS_THEMES.HERO }
          onCancel={ () => {
            this.setState({ popupIssueUrl: false });
            this.setInterval();
          }}
        />
      );
    };

    private renderItem(ad: AdvertisementCard, cardIndex: number) {
      return (
        <div className={ styles.content }>
          <div className={ styles.badgeContainer }>
            { ad.cardAdvertisementBadges.map((badge, index) => (
              <span
                key={ `badge-${index}` }
                dangerouslySetInnerHTML={{ __html: badge.title }}
                className={ badge.badgeType === BadgeType.PRIMARY ? styles.primary : styles.notice }
              />
            )) }
          </div>
          <h2 className={ styles.adTitle }>{ ad.title }</h2>
          <h1 className={ styles.cardName }><b>{ ad.card.companyName }</b> { ad.card.name }</h1>
          <div className={ styles.card }>
            <img
              src={ ad.card.imageUrl }
              className={ styles.defaultImage }
              onLoad={ this.onLoadImage }
            />
            <div className={ styles.cardDescription }>
              <h3 className={ styles.benefitTitle }>{ ad.benefitTitle }</h3>
              <p className={ styles.benefitDesc }>{ ad.benefitSubTitle }</p>
            </div>
            <div className={ styles.cardLinks }>
              <a
                href={ BANKSALAD_SITEMAP_BY_ID.CARD(ad.card.id) }
                className={ styles.cardDetailLink }
                onClick={ this.onClickDetailButton(ad, cardIndex) }
              >
                상세보기
              </a>
              <a
                target="_blank"
                className={ styles.cardIssueLink }
                onClick={ this.onClickIssueButton(ad, cardIndex) }
              >
                카드신청
              </a>
            </div>
          </div>
        </div>
      );
    }

    private movePage = (page: number) => () => {
      this.setInterval();
      this.setState({
        page: page
      });
    };

    private onPrev = () => {
      this.setInterval();
      const current = this.state.page - 1;
      this.setState({
        page: current === -1 ? this.props.cards.size - 1 : current
      });
    };

    private onNext = () => {
      this.setInterval();
      const current = this.state.page + 1;
      this.setState({
        page: current === this.props.cards.size ? 0 : current
      });
    };

    private onClickDetailButton = (ad: AdvertisementCard, index: number) => () => {
      sendGAEvent(
        GA_DOMAIN.CARD,
        GA_CATEGORY.CARDS_THEMES.HERO,
        GA_ACTION.LINK.CARDS_DETAIL,
        `상세보기_${ad.card.companyName}_${ad.card.id}_${index}`
      );
    };

    private onClickIssueButton = (ad: AdvertisementCard, index: number) => () => {
      sendGAEvent(
        GA_DOMAIN.CARD,
        GA_CATEGORY.CARDS_THEMES.HERO,
        GA_ACTION.POPUP_ISSUE,
        `카드신청_${ad.card.companyName}_${ad.card.id}_${index}`
      );
      sendPixelEvent(PIXEL.CARD_ISSUE, `카드_${ad.card.companyName}`);
      clearInterval(this.carouselTimer);
      this.setState({ popupIssueUrl: true });
    };

    private setInterval = () => {
      clearInterval(this.carouselTimer);
      this.carouselTimer = setInterval(this.onNext, 3000);
    };

    private onLoadImage = (e: React.ChangeEvent<HTMLImageElement>) => {
      const element = e.target;
      element.className = element.height <= element.width 
        ? styles.landscapeImage : styles.portraitImage;
    };

    private onChangeBannerOrder = (nextPosition: number) => {
      const { position } = this.state;
      position <= nextPosition ?
        this.onNext() :
        this.onPrev();
    };
}
