import { List } from 'immutable';
import { Entity } from 'core/entity';
import { CardSummary } from 'core/entity/card/summary';

export class AdvertisementCard extends Entity {
    title: string;
    card: CardSummary;
    benefitTitle: string;
    benefitSubTitle: string;
    cardAdvertisementBadges: List<CardAdvertisementBadge>;
    issueUrlDesktop: string;
    issueUrlMobile: string;
    order: number;
    exposable: boolean;

    constructor(
        title: string,
        card: CardSummary,
        benefitTitle: string,
        benefitSubTitle: string,
        cardAdvertisementBadges: List<CardAdvertisementBadge>,
        issueUrlDesktop: string,
        issueUrlMobile: string,
        order: number,
        exposable: boolean
    ) {
        super();
        this.title = title;
        this.card = card;
        this.benefitTitle = benefitTitle;
        this.benefitSubTitle = benefitSubTitle;
        this.cardAdvertisementBadges = cardAdvertisementBadges;
        this.issueUrlDesktop = issueUrlDesktop;
        this.issueUrlMobile = issueUrlMobile;
        this.order = order;
        this.exposable = exposable;
    }
}

export class CardAdvertisementBadge {
    title: string;
    badgeType: BadgeType;

    constructor(
        title: string,
        badgeType: BadgeType
    ) {
        this.title = title;
        this.badgeType = badgeType;
    }
}

export enum BadgeType {
    NOTICE,
    PRIMARY
}
