import React from 'react';
import moment from 'moment';

import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { Card } from 'core/entity/card';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import { toTimeString } from 'utils/index';
import { CardIssuePromotionAlert } from 'presentation/components/flexible-alert/card-issue-promotion';
import { CardIssueUrlAlert } from 'presentation/components/alert/card-issue-url';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';

import styles from './styles.pcss';
const ONE_DAY_SECS = 86400;

interface Props {
    card: Card,
    rank: number
}

interface State {
    alert: boolean
}

export class CardAnnualFeePromotionItem extends React.Component<Props, State> {
    state = {
        alert: false
    };

    render() {
        const { card } = this.props;
        const { alert } = this.state;

        let promotionEndTime = card.annualCostPromotion.endDate;
        let promotionStartTime = card.annualCostPromotion.startDate;
        let timeNow = new Date();

        let finished = promotionEndTime !== null && promotionEndTime < new Date();
        let timeDiff = promotionEndTime !== null ? promotionEndTime.getTime() - new Date().getTime() : 0;
        let leftOneDay = (
            (moment.duration(promotionEndTime.getTime() - timeNow.getTime()).asSeconds()) < ONE_DAY_SECS
        );
        let createdToday = (
            (moment.duration(timeNow.getTime() - promotionStartTime.getTime()).asSeconds()) < ONE_DAY_SECS
        );

        if (leftOneDay && !finished) {
            setTimeout(() => {
                this.forceUpdate();
            }, 1000);
        }

        return(
            <div className={ styles.wrap }>
                <div className={ styles.details }>
                    <div className={ styles.noticeDate }>
                        { createdToday && <div className={ styles.openToday }><span>오늘오픈</span></div> }
                        { leftOneDay && <div className={ styles.closeToday }><span>마감임박</span></div> }
                    </div>
                    <h4 className={ styles.title }>
                        <strong>{ card.companyName }</strong>
                        { card.name }
                    </h4>
                    <div className={ styles.image }>
                        <img
                            src={ card.imageUrl }
                            className={ styles.defaultImage }
                            onLoad={ this.onLoadImage }
                        />
                    </div>
                    {
                        finished ? <div className={ styles.timeOff }>마감되었습니다</div> :
                            leftOneDay ? (<div className={ styles.timeLeft }> { toTimeString(timeDiff) } 남음</div>) :
                            (
                                <span className={ styles.timeEnd }>
                                    { this.getExpiredDate(promotionEndTime) }
                                </span>
                            )
                    }
                    <div className={ styles.benefits }>{ card.annualCostPromotion.summary }</div>
                    <span className={ styles.desc }>
                        <dl>
                            <dt>연회비</dt>
                            <dd>{ card.minimumAnnualCost === card.maximumAnnualCost ?
                                `${card.maximumAnnualCost/1000},000원 ` :
                                `${card.minimumAnnualCost/1000},000원 ~ ${card.maximumAnnualCost/1000},000원`}
                            </dd>
                        </dl>
                    </span>
                </div>
                <div className={ styles.buttons }>
                    <a
                        href= { BANKSALAD_SITEMAP_BY_ID.CARD(card.id) }
                        onClick={ () => {
                            sendGAEvent(
                                GA_DOMAIN.CARD,
                                GA_CATEGORY.CARDS_ANNUAL_FEE_PROMOTION.RESULT_LIST,
                                GA_ACTION.LINK.CARDS_DETAIL,
                                `상세보기_${card.companyName}_${card.id}`
                            )
                        } }
                        className={ styles.detail }
                    >
                        <span>상세보기</span>
                    </a>
                    <button
                        onClick={ () => {
                            sendGAEvent(
                                GA_DOMAIN.CARD,
                                GA_CATEGORY.CARDS_ANNUAL_FEE_PROMOTION.RESULT_LIST,
                                GA_ACTION.POPUP_ISSUE,
                                `카드신청_${card.companyName}_${card.id}`
                            );
                            sendPixelEvent(PIXEL.CARD_ISSUE, `카드_${card.companyName}`);
                            this.setState({ alert: true });
                        } }
                        className={ styles.issue }
                    >
                        <span>카드신청</span>
                    </button>
                    { alert && this.renderAlert() }
                </div>
            </div>
        )
    }

    private renderAlert = () => {
        const { card } = this.props;

        return card.annualCostPromotion ?
            <CardIssuePromotionAlert
                cardId={ card.id }
                description={ card.annualCostPromotion.description }
                summary={ card.annualCostPromotion.summary }
                companyName={ card.companyName }
                issueUrlDesktop={ card.issueUrlDesktop }
                issueUrlMobile={ card.issueUrlMobile }
                onCancel={ () => this.setState({ alert: false }) }
            /> :
            <CardIssueUrlAlert
                cardId={ card.id }
                issueUrlDesktop={ card.issueUrlDesktop }
                issueUrlMobile={ card.issueUrlMobile }
                companyName={ card.companyName }
                companyImageUrl={ card.companyImageUrl }
                gaCategory={ GA_CATEGORY.CARDS_ANNUAL_FEE_PROMOTION.RESULT_LIST }
                onCancel={ () => this.setState({ alert: false }) }
            />;
    };

    private getExpiredDate = (date: Date) =>
        `${date.format('YYYY.M.D')}까지`;

    private onLoadImage = (e: React.ChangeEvent<HTMLImageElement>) => {
        const element = e.target;
        element.className = element.height <= element.width ? styles.landscapeImage : styles.portraitImage;
    }
}
