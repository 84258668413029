import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { format } from 'date-fns';
import { Lottie } from 'presentation/components/lottie';
import { trackClickLnb } from 'presentation/module/analytics/events/trackers/gnb';
import {
  BASE_URL,
  CDN_BASE_URL,
} from 'presentation/routes-app/service-center/components/list-item';
import { getBenefitSumText } from 'presentation/routes/sectors/card/utils';
import queryString from 'query-string';
import React, { ReactNode, useEffect, useState } from 'react';

import {
  BdsColorIconApartmentMoneyBagPercentOriginalSvg,
  BdsColorIconApartmentOriginalSvg,
  BdsColorIconArrowCirclePathCoinWonOriginalSvg,
  BdsColorIconCarOriginalSvg,
  BdsColorIconCreditCardOriginalSvg,
  BdsColorIconCurationLoanSvg,
  BdsColorIconDnaOriginalSvg,
  BdsColorIconGaugeCheckMarkOriginal,
  BdsColorIconGaugeWonOriginalSvg,
  BdsColorIconHealthInsuranceShieldGreenOriginalSvg,
  BdsColorIconLightBulbOriginalSvg,
  BdsColorIconPaperHolderOriginalSvg,
  BdsColorIconPigWonCoinOriginalSvg,
  BdsColorIconTicketWonOriginalSvg,
  BdsColorIllustrationCurationGetCouponsSvg,
  BdsColorIllustrationCurationReturnCheckSvg,
  BdsColorIllustrationNewsCardRecommendationSvg,
  BdsMonoIconCarOriginalSvg,
} from '../shared/bpl/icons';
import { GnbFeaturedItemDesktop } from './gnb-navigation-bar-desktop';
import styles from './styles.pcss';

export type GnbUrlObject = {
  url: string;
  linkTarget?: '_blank' | '_self';
};

export type MobileFeaturedItem = {
  title: string;
  link: GnbUrlObject;
  type?: 'BEST' | 'New';
};

export type Gnb = {
  title: string;
  id: FinanceSectorID;
  icon: ReactNode;
  link: GnbUrlObject;
  featuredItem: ReactNode;
  featuredItemsInMobile?: MobileFeaturedItem[]; // 데탑 해상도에서의 featuredItem 목록과 같은 data 로 활용하기 어려워 따로 분리합니다.
  lnbCategories?: LnbCategory[];
};

type LnbCategory = {
  title?: string;
  featuredItemForDesktop?: ReactNode;
  items: LnbCategoryItem[];
};

export type LnbCategoryItem = {
  title: string;
  url: string;
};

export type GnbFromStrapi = {
  title: string;
  lnbCategories: LnbCategory[];
};

const RefinancingFeaturedItem = () => (
  <GnbFeaturedItemDesktop
    title={
      <div className={styles.featuredItemTitle}>
        대출 갈아타기로 이자 부담,
        <br />확 낮춰보세요
      </div>
    }
    image={<Lottie lottieFile={Lottie.Animation.SIMPLELOAN_MAIN} loop />}
    buttonText="대출 갈아타기"
    onButtonClick={() => {
      trackClickLnb('대환대출', '대출 갈아타기');
      window.location.assign(LOAN_REFINANCING);
    }}
  />
);

const LoanFeaturedItem = () => (
  <GnbFeaturedItemDesktop
    title={
      <div className={styles.featuredItemTitle}>
        신용대출, 햇살론,
        <br />
        직장인 대출까지
        <br />한 번에 비교하세요
      </div>
    }
    image={<Lottie lottieFile={Lottie.Animation.SIMPLELOAN_MAIN} loop />}
    buttonText="내 대출금리 조회"
    onButtonClick={() => {
      trackClickLnb('신용대출', '신용대출 금리 비교 한 번에');
      window.location.assign(PREQUALIFICATION_LOANS_CREDIT);
    }}
  />
);

const CardFeaturedItem = () => {
  const [cardBenefitSumText, setCardBenefitSumText] = useState('추가 혜택');

  useEffect(() => {
    (async () => {
      const benefitSumText = await getBenefitSumText();
      setCardBenefitSumText(benefitSumText);
    })();
  });

  return (
    <GnbFeaturedItemDesktop
      title={
        <div className={styles.featuredItemTitle}>
          신용카드 캐시백 신청하고
          <br />
          <span className={styles.greenText}>{cardBenefitSumText}</span> 받으세요
        </div>
      }
      image={
        <div className={styles.featuredItemImageContainer}>
          <BdsColorIllustrationNewsCardRecommendationSvg height={111} width={213} />
        </div>
      }
      buttonText="캐시백 이벤트 응모하기"
      onButtonClick={() => {
        trackClickLnb('신용카드', '신용카드 캐시백 이벤트');
        window.location.assign(BEST_CARD_CREDIT);
      }}
    />
  );
};

const HealthInsuranceFeaturedItem = () => {
  return (
    <GnbFeaturedItemDesktop
      title={
        <div className={styles.featuredItemTitle}>
          낸 만큼 돌려받을 수 있을까?
          <br />
          가장 많이 돌려받는 보험 진단
        </div>
      }
      image={
        <div className={styles.featuredItemImageContainer}>
          <BdsColorIllustrationCurationReturnCheckSvg width={(250 * 129) / 160} height={129} />
        </div>
      }
      buttonText="돌려받는 금액 조회하기"
      onButtonClick={() => {
        trackClickLnb('건강 보험', '돌려받는 금액 조회하기');
        window.location.assign(HEALTH_INSURANCE);
      }}
    />
  );
};

const MortgageFeaturedItem = () => (
  <GnbFeaturedItemDesktop
    title={
      <div className={styles.featuredItemTitle}>
        주택담보대출 내 금리
        <br />한 번에 비교하세요
      </div>
    }
    image={<Lottie lottieFile={Lottie.Animation.MORTGAGE_LOAN} loop />}
    buttonText="내 최저금리 조회하기"
    onButtonClick={() => {
      trackClickLnb('주택담보대출', '주택담보대출 금리 비교 한 번에');
      window.location.assign(PREQUALIFICATION_LOANS_MORTGAGE);
    }}
  />
);

const JeonseFeaturedItem = () => (
  <GnbFeaturedItemDesktop
    title={
      <div className={styles.featuredItemTitle}>
        전세자금대출 내 금리
        <br />한 번에 비교하세요
      </div>
    }
    image={
      <img
        style={{ width: '100%' }}
        src={`${CDN_BASE_URL}/lottie/curation/jeonse-house.png`}
        alt="전세자금대출 대표 이미지"
      />
    }
    buttonText="내 최저금리 조회하기"
    onButtonClick={() => {
      trackClickLnb('전세자금대출', '전세자금대출 금리 비교 한 번에');
      window.location.assign(PREQUALIFICATION_LOANS_JEONSE);
    }}
  />
);

const CarInsuranceFeaturedItem = () => (
  <GnbFeaturedItemDesktop
    title={
      <div className={styles.featuredItemTitle}>
        자동차보험으로
        <br />
        최대 47,000원 혜택 받기
      </div>
    }
    image={<Lottie lottieFile={Lottie.Animation.CAR_INSURANCE} loop />}
    buttonText="확인하러 가기"
    onButtonClick={() => {
      trackClickLnb('자동차보험', '자동차보험');
      window.location.assign(CAR_INSURANCE);
    }}
  />
);

const CouponFeaturedItem = () => (
  <GnbFeaturedItemDesktop
    title={
      <div className={styles.featuredItemTitle}>
        대출금리 쿠폰으로
        <br />
        얼마나 할인받는지 확인하세요
      </div>
    }
    image={<BdsColorIllustrationCurationGetCouponsSvg height={160} />}
    buttonText="쿠폰 이벤트 보기"
    onButtonClick={() => {
      trackClickLnb('대출금리쿠폰', '쿠폰 이벤트 보기');
      window.location.assign(LOAN_COUPON_MONTHLY_YEAR_MONTH);
    }}
  />
);

const CreditFeaturedItem = () => (
  <GnbFeaturedItemDesktop
    title={
      <div className={styles.featuredItemTitle}>
        무료 신용점수 조회
        <br />
        횟수제한 없이
      </div>
    }
    image={
      <figure
        style={{
          display: 'flex',
          padding: '15px 0',
        }}
      >
        <BdsColorIconGaugeCheckMarkOriginal title="신용점수 아이콘" width={130} height={130} />
      </figure>
    }
    buttonText="신용 조회 확인하기"
    onButtonClick={() => {
      trackClickLnb('신용점수', '신용 조회 확인하기');
      window.location.assign(CREDIT);
    }}
  />
);

const CardLnbCategoryFeaturedItem = () => {
  return (
    <button
      className={styles.lnbCategoryFeaturedItem}
      onClick={() => {
        trackClickLnb('신용카드', '나에게 맞는 신용카드 추천');
        window.location.assign(CARDS_QUESTIONS);
      }}
    >
      <div className={styles.lnbCategoryFeaturedItemTextContainer}>
        <p className={styles.lnbCategoryFeaturedItemTitleGreen}>국내 최초</p>
        <p className={styles.lnbCategoryFeaturedItemTitle}>{`나에게 맞는\n신용카드 추천`}</p>
      </div>

      <div className={styles.lnbCategoryFeaturedItemLottieContainer}>
        <Lottie lottieFile={Lottie.Animation.CARD_ROTATE} loop />
      </div>
    </button>
  );
};

const CouponLnbCategoryFeaturedItem = () => {
  return (
    <button
      className={styles.lnbCategoryFeaturedItem}
      onClick={() => {
        trackClickLnb('대출금리쿠폰', '내 대출 금리 조회');
        window.location.assign(PREQUALIFICATION_LOANS_CREDIT);
      }}
    >
      <div className={styles.lnbCategoryFeaturedItemTextContainer}>
        <p className={styles.lnbCategoryFeaturedItemTitleGreen}>최저금리 대출 비교</p>
        <p className={styles.lnbCategoryFeaturedItemTitle}>{`내 대출 금리 조회`}</p>
      </div>

      <BdsColorIconCurationLoanSvg
        width={48}
        height={48}
        className={styles.lnbCategoryFeaturedItemIcon}
      />
    </button>
  );
};

const CreditLoanLnbCategoryFeaturedItem = () => {
  return (
    <button
      className={styles.lnbCategoryFeaturedItem}
      onClick={() => {
        trackClickLnb('신용대출', '금리할인 쿠폰으로 최저가 대출받기');
        window.location.assign(LOAN_COUPON_MONTHLY_YEAR_MONTH);
      }}
    >
      <div className={styles.lnbCategoryFeaturedItemTextContainer}>
        <p className={styles.lnbCategoryFeaturedItemTitleGreen}>대출금리쿠폰</p>
        <p className={styles.lnbCategoryFeaturedItemTitle}>{`금리할인 쿠폰으로\n최저가 대출받기`}</p>
      </div>

      <BdsColorIconTicketWonOriginalSvg
        width={48}
        height={48}
        className={styles.lnbCategoryFeaturedItemIcon}
      />
    </button>
  );
};

const CreditLnbFeaturedItem = () => (
  <button
    className={styles.lnbCategoryFeaturedItem}
    onClick={() => {
      trackClickLnb('신용점수', '신용분석 리포트');
      window.location.assign(CREDIT_REPORT);
    }}
  >
    <div className={styles.lnbCategoryFeaturedItemTextContainer}>
      <p className={styles.lnbCategoryFeaturedItemTitleGreen}>신용분석 리포트</p>
      <p className={styles.lnbCategoryFeaturedItemTitle}>신용점수 관리의 시작</p>
    </div>

    <BdsColorIconPaperHolderOriginalSvg
      width={48}
      height={48}
      className={styles.lnbCategoryFeaturedItemIcon}
    />
  </button>
);

const CarInsuranceLnbCategoryFeaturedItem = () => {
  return (
    <button
      className={styles.lnbCategoryFeaturedItem}
      onClick={() => {
        trackClickLnb('자동차보험', '9개사 최저가 한 눈에 비교하기');
        window.location.assign(CAR_INSURANCE_PREQUALIFICATION);
      }}
    >
      <div className={styles.lnbCategoryFeaturedItemTextContainer}>
        <p className={styles.lnbCategoryFeaturedItemTitleGreen}>자동차보험 비교</p>
        <p className={styles.lnbCategoryFeaturedItemTitle}>{`9개사 최저가\n한 눈에 비교하기`}</p>
      </div>

      <BdsColorIconCarOriginalSvg
        width={48}
        height={48}
        className={styles.lnbCategoryFeaturedItemIcon}
      />
    </button>
  );
};


const PREQUALIFICATION_LOANS_CREDIT = `${BASE_URL}/prequalification/loans/credit`;
const BEST_CARD_CREDIT = `${BASE_URL}/chart/cards?tab=event`;
const CARDS_QUESTIONS = `${BASE_URL}/cards/questions`;
const PREQUALIFICATION_LOANS_MORTGAGE = `${BASE_URL}/prequalification/loans/mortgage`;
const PREQUALIFICATION_LOANS_JEONSE = `${BASE_URL}/prequalification/loans/jeonse`;
const LOAN_REFINANCING = `${BASE_URL}/loan/refinancing/intro`;
const CREDIT = `${BASE_URL}/credit`;
const CREDIT_REPORT = `${BASE_URL}/credit/report/login`;
const CAR_INSURANCE = `${BASE_URL}/car-insurance`;
const CAR_INSURANCE_PREQUALIFICATION = `${BASE_URL}/car-insurance/prequalification`;
const HEALTH_INSURANCE = `${BASE_URL}/health/insurance`;
const LOAN_COUPON_MONTHLY_YEAR_MONTH = `${BASE_URL}/loan/coupon/monthly/${format(Date.now(), 'yyyyMM')}`;

const GNB_DATA: Gnb[] = [
  {
    title: '신용카드',
    id: FinanceSectorID.CARD,
    icon: <BdsColorIconCreditCardOriginalSvg width={28} height={28} />,
    link: { url: BEST_CARD_CREDIT, linkTarget: '_self' },
    featuredItem: <CardFeaturedItem />,
    featuredItemsInMobile: [
      {
        title: '신용카드 캐시백 이벤트',
        link: { url: BEST_CARD_CREDIT, linkTarget: '_self' },
        type: 'BEST',
      },
      {
        title: '나에게 맞는 신용카드 추천',
        link: { url: CARDS_QUESTIONS, linkTarget: '_self' },
        type: 'BEST',
      },
    ],
  },
  {
    title: '신용대출',
    id: FinanceSectorID.LOAN,
    icon: <BdsColorIconGaugeWonOriginalSvg width={28} height={28} />,
    link: { url: PREQUALIFICATION_LOANS_CREDIT, linkTarget: '_self' },
    featuredItem: <LoanFeaturedItem />,
    featuredItemsInMobile: [
      {
        title: '신용대출 금리 비교 한 번에',
        link: { url: PREQUALIFICATION_LOANS_CREDIT, linkTarget: '_self' },
        type: 'BEST',
      },
      {
        title: '이달의 대출 쿠폰',
        link: { url: LOAN_COUPON_MONTHLY_YEAR_MONTH, linkTarget: '_self' },
      },
    ],
  },
  {
    title: '대환대출',
    id: FinanceSectorID.REFINANCING,
    icon: <BdsColorIconArrowCirclePathCoinWonOriginalSvg width={28} height={28} />,
    link: {
      url: LOAN_REFINANCING,
      linkTarget: '_self',
    },
    featuredItem: <RefinancingFeaturedItem />,
    featuredItemsInMobile: [
      {
        title: '대출 갈아타기',
        link: {
          url: LOAN_REFINANCING,
          linkTarget: '_self',
        },
        type: 'New',
      },
    ],
  },
  {
    title: '주택담보대출',
    id: FinanceSectorID.MORTGAGE,
    icon: <BdsColorIconApartmentOriginalSvg width={28} height={28} />,
    link: { url: PREQUALIFICATION_LOANS_MORTGAGE, linkTarget: '_self' },
    featuredItem: <MortgageFeaturedItem />,
    featuredItemsInMobile: [
      {
        title: '주택담보대출 금리 비교 한 번에',
        link: { url: PREQUALIFICATION_LOANS_MORTGAGE, linkTarget: '_self' },
        type: 'BEST',
      },
    ],
  },
  {
    title: '전세자금대출',
    id: FinanceSectorID.JEONSE,
    icon: <BdsColorIconApartmentMoneyBagPercentOriginalSvg width={28} height={28} />,
    link: { url: PREQUALIFICATION_LOANS_JEONSE, linkTarget: '_self' },
    featuredItem: <JeonseFeaturedItem />,
    featuredItemsInMobile: [
      {
        title: '전세자금대출 금리 비교 한 번에',
        link: { url: PREQUALIFICATION_LOANS_JEONSE, linkTarget: '_self' },
        type: 'BEST',
      },
    ],
  },
  {
    title: '대출금리쿠폰',
    id: FinanceSectorID.COUPON,
    icon: <BdsColorIconTicketWonOriginalSvg />,
    link: { url: LOAN_COUPON_MONTHLY_YEAR_MONTH, linkTarget: '_self' },
    featuredItem: <CouponFeaturedItem />,
    featuredItemsInMobile: [
      {
        title: '이달의 대출 쿠폰',
        link: { url: LOAN_COUPON_MONTHLY_YEAR_MONTH, linkTarget: '_self' },
      },
    ],
  },
  {
    title: '신용점수',
    id: FinanceSectorID.CREDIT,
    icon: <BdsColorIconGaugeCheckMarkOriginal title="신용점수 아이콘" width={24} height={24} />,
    link: { url: CREDIT, linkTarget: '_self' },
    featuredItem: <CreditFeaturedItem />,
    featuredItemsInMobile: [
      {
        title: '신용 조회 확인하기',
        link: { url: CREDIT, linkTarget: '_self' },
      },
      {
        title: '신용분석 리포트',
        link: { url: CREDIT_REPORT, linkTarget: '_self' },
        type: 'New',
      },
    ],
  },
  {
    title: '예적금',
    id: FinanceSectorID.SAVING,
    icon: <BdsColorIconPigWonCoinOriginalSvg width={28} height={28} />,
    link: {
      url: `${BASE_URL}/chart/deposits`,
      linkTarget: '_self',
    },
    featuredItem: undefined,
    featuredItemsInMobile: undefined,
  },
  {
    title: '무료 건강 검사',
    id: FinanceSectorID.HEALTH_SELF_TESTING,
    icon: <BdsColorIconDnaOriginalSvg width={28} height={28} />,
    link: {
      url: `${BASE_URL}/health/dtc-genetic-testing`,
      linkTarget: '_blank',
    },
    featuredItem: undefined,
    featuredItemsInMobile: undefined,
    lnbCategories: undefined,
  },
  {
    title: '건강 보험',
    id: FinanceSectorID.HEALTH_INSURANCE,
    icon: <BdsColorIconHealthInsuranceShieldGreenOriginalSvg width={28} height={28} />,
    link: {
      url: `${BASE_URL}/health/insurance?${queryString.stringify({
        utm_source: 'BS_web',
        utm_medium: 'gnb',
        param_media_type: 'owned',
      })}`,
      linkTarget: '_blank',
    },
    featuredItem: <HealthInsuranceFeaturedItem />,
    featuredItemsInMobile: [
      {
        title: '가장 많이 돌려받는 보험 진단',
        link: { url: HEALTH_INSURANCE, linkTarget: '_self' },
        type: 'New',
      },
    ],
    lnbCategories: undefined,
  },
  {
    title: '자동차보험',
    id: FinanceSectorID.CAR_INSURANCE,
    icon: <BdsMonoIconCarOriginalSvg />,
    link: {
      url: CAR_INSURANCE_PREQUALIFICATION,
      linkTarget: '_self',
    },
    featuredItem: <CarInsuranceFeaturedItem />,
    featuredItemsInMobile: [
      {
        title:  "자동차보험 최대 47,000원 혜택",
        link: {
          url: CAR_INSURANCE,
          linkTarget: '_self',
        },
        type: 'New',
      },
      {
        title: '자동차보험 최저가 한눈에 비교',
        link: {
          url: CAR_INSURANCE_PREQUALIFICATION,
          linkTarget: '_self',
        },
      },
    ],
  },
  {
    title: '돈이 된다! 머니피드',
    id: FinanceSectorID.ARTICLE,
    icon: <BdsColorIconLightBulbOriginalSvg width={28} height={28} />,
    link: {
      url:
        // eslint-disable-next-line max-len
        `${BASE_URL}/articles/home`,
      linkTarget: '_self',
    },
    featuredItem: undefined,
    featuredItemsInMobile: undefined,
  },
];

export const mapGnbData = (gnbFromStrapi: GnbFromStrapi[]): Gnb[] => {
  return GNB_DATA.map(gnbItem => {
    const gnbItemFromStrapi = gnbFromStrapi.find(item => item.title === gnbItem.title);

    if (gnbItemFromStrapi === undefined) {
      throw new Error(
        `Strapi 의 응답 중 gnb 이름(${gnbItem.title})과 일치하는 값이 없습니다. title 값을 확인해주세요.`
      );
    }

    const lnbCategories = gnbItemFromStrapi.lnbCategories;

    /* 신용카드의 경우, 첫번째 LNB 카테고리를 변경해준다. */
    if (gnbItem.title === '신용카드') {
      const firstLnbCategory = {
        ...lnbCategories[0],
        featuredItemForDesktop: <CardLnbCategoryFeaturedItem />,
      };

      return {
        ...gnbItem,
        lnbCategories: [firstLnbCategory, ...lnbCategories.slice(1)],
      };
    }

    /* 대출금리쿠폰의 경우, 첫번째 LNB 카테고리에 featuredItemForDesktop만 노출한다. */
    if (gnbItem.title === '대출금리쿠폰') {
      const firstLnbCategory: LnbCategory = {
        title: undefined,
        items: [],
        featuredItemForDesktop: <CouponLnbCategoryFeaturedItem />,
      };

      return {
        ...gnbItem,
        lnbCategories: [firstLnbCategory, ...lnbCategories],
      };
    }

    /* 신용대출의 경우, 첫번째 LNB 카테고리를 변경해준다. */
    if (gnbItem.title === '신용대출') {
      const firstLnbCategory = {
        ...lnbCategories[0],
        featuredItemForDesktop: <CreditLoanLnbCategoryFeaturedItem />,
      };

      return {
        ...gnbItem,
        lnbCategories: [firstLnbCategory, ...lnbCategories.slice(1)],
      };
    }

    /* 자동차보험의 경우, 첫번째 LNB 카테고리를 변경해준다. */
    if(gnbItem.title === '자동차보험') {
      const firstLnbCategory = {
        ...lnbCategories[0],
        featuredItemForDesktop: <CarInsuranceLnbCategoryFeaturedItem />,
      };

      return {
        ...gnbItem,
        lnbCategories: [firstLnbCategory, ...lnbCategories.slice(1)],
      };
    }

    /* 신용점수의 경우, 첫번째 LNB 카테고리에 featuredItemForDesktop만 노출한다. */
    if (gnbItem.title === '신용점수') {
      const firstLnbCategory: LnbCategory = {
        title: undefined,
        items: [],
        featuredItemForDesktop: <CreditLnbFeaturedItem />,
      };

      return {
        ...gnbItem,
        lnbCategories: [firstLnbCategory, ...lnbCategories],
      };
    }

    return {
      ...gnbItem,
      lnbCategories,
    };
  });
};
