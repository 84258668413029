import { ComparableCard } from 'core/entity/comparable-card';
import { ComparableCardConfiguration } from 'core/entity/comparable-card/configuration';
import { ContentCardComparison } from 'core/entity/content/card-comparison';
import { List } from 'immutable';
import { CardIssueUrlAlert } from 'presentation/components/alert/card-issue-url';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import { toCurrency } from 'presentation/module/sugar';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.pcss';

interface Props {
    content: ContentCardComparison;
}

interface State {
    alert: boolean;
    cardForIssuing: ComparableCard;
}

export class ContentCardComparisonViewer extends React.Component<Props, State> {
    state = {
      alert: false,
      cardForIssuing: null as ComparableCard
    };

    render() {
      const { content } = this.props;
      const { alert, cardForIssuing } = this.state;
      const numberOfCards = content.article.sets.flatMap(it => it.configurations).size;

      return (
        <div>
          {
            (content.thumbnailImageUrl && content.thumbnailImageUrl.length > 0) && (
              <div
                className={ styles.thumbnail }
                style={{ backgroundImage: `url("${content.thumbnailImageUrl}")` }}
              >
                            썸네일 이미지
              </div>
            )
          }
          <p
            dangerouslySetInnerHTML={ { __html: content.article.introduction } }
            className={ styles.description }
          />
          <div className={ styles.recommendedCardContainer }>
            <span><b>{ `${numberOfCards}개` }</b>의 카드를 추천합니다.</span>
            <ol className={ styles.recommendedCards }>
              {
                content.article.sets.map((set, index) => (
                  <li key={ `recommended-card-${ index }` }>
                    <h1>{ `${ index + 1 }. ${ set.title }` }</h1>
                    <ul key={ `comparable-cardset-${ index }` }>
                      {
                        set.configurations.map((config, i) =>
                          this.renderConfig(config, i))
                      }
                    </ul>
                    {
                      set.description && (
                        <p
                          dangerouslySetInnerHTML={ { __html: set.description } }
                          className={ styles.cardDescription }
                        />
                      )
                    }
                  </li>
                ) )
              }
            </ol>
          </div>
          <p
            dangerouslySetInnerHTML={ { __html: content.article.conclusion } }
            className={ styles.conclusion }
          />
          <div className={ styles.terms }>
                    본 게시물은 뱅크샐러드에서 대가관계없이 자체 제작한 게시물입니다
          </div>
          {
            alert &&
                    <CardIssueUrlAlert
                      cardId={ cardForIssuing.id }
                      issueUrlDesktop={ cardForIssuing.issuingUrl.desktop }
                      issueUrlMobile={ cardForIssuing.issuingUrl.mobile }
                      companyName={ cardForIssuing.company.name }
                      companyImageUrl={ cardForIssuing.imageUrl }
                      gaCategory={ GA_CATEGORY.CARDS_THEMES_DETAIL.CARDS }
                      onCancel={ () => this.setState({ alert: false }) }
                    />
          }
        </div>
      );
    }

    private renderConfig = (config: ComparableCardConfiguration, index: number) => {
      return (
        <li key={ `comparable-card-${ index }` }>
          <div className={ styles.cardBox }>
            <div className={ styles.cardInfo }>
              <img
                src={ config.card.company.imageUrl }
                className={ styles.companyImage }
              />
              <img
                src={ config.card.imageUrl }
                className={ styles.defaultImage }
                onLoad={ this.onLoadImage }
              />
              <h1 className={ styles.cardName }>{ config.card.name }</h1>
              <div className={ styles.cardAttributes }>
                <span className={ styles.cardAttribute }>
                  <b>{ this.toCardTypeString(config.card.type) }</b>
                </span>
                <span className={ styles.cardAttribute }>
                  <b>연회비</b> { `${ toCurrency(config.card.annualCost) }원` }
                </span>
              </div>
            </div>
            <div className={ styles.cardLinks }>
              <a
                href={ BANKSALAD_SITEMAP_BY_ID.CARD(config.card.id) }
                onClick={ () => sendGAEvent(
                  GA_DOMAIN.CARD,
                  GA_CATEGORY.CARDS_THEMES_DETAIL.CARDS,
                  GA_ACTION.LINK.CARDS_DETAIL,
                  `상세보기_${config.card.company.name}_${config.card.id}_${index}`
                ) }
                className={ styles.cardDetailLink }
              >
                상세보기
              </a>
              <a
                onClick={
                  () => {
                    sendGAEvent(
                      GA_DOMAIN.CARD,
                      GA_CATEGORY.CARDS_THEMES_DETAIL.CARDS,
                      GA_ACTION.POPUP_ISSUE,
                      `카드신청_${config.card.company.name}_${config.card.id}_${index}`
                    );
                    sendPixelEvent(PIXEL.CARD_ISSUE, `카드_${config.card.company.name}`);
                    this.setState({
                      alert: true,
                      cardForIssuing: config.card
                    });
                  }
                }
                className={ styles.cardIssueLink }
              >
                            카드신청
              </a>
            </div>
          </div>
          <div className={ styles.cardCaption }>
            { config.caption && <p dangerouslySetInnerHTML={ { __html: config.caption } }/> }
          </div>
        </li>
      );
    };

    private toCardTypeString = (type: ComparableCard.Type) => {
      switch (type) {
      case ComparableCard.Type.CREDIT:
        return '신용';
      case ComparableCard.Type.CHECK:
        return '체크';
      case ComparableCard.Type.HYBRID:
        return '하이브리드';
      }
    };

    private onLoadImage = (e: React.ChangeEvent<HTMLImageElement>) => {
      const element = e.target;
      element.className = element.height <= element.width 
        ? styles.landscapeImage : styles.portraitImage;
    };
}
