import { Footer } from 'presentation/components/footer';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { CardsThemesView } from 'presentation/routes/cards/themes/view';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React from 'react';

export const CardsThemesRoute = () => (
  <>
    <MetaHelmet meta={ META_SET.CARDS_THEMES } />
    <CardsThemesView />
    <Footer />
  </>
);
