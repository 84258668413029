import Axios from 'axios';
import { CardSummary } from 'core/entity/card/summary';
import { ContentCardComparisonSummary } from 'core/entity/content/card-comparison/summary';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { AdvertisementCard } from 'core/entity/poong-legacy/advertisement-card';
import { ContentSummaryMapper } from 'data/http/mapper/content/summary';
import { List } from 'immutable';
import { AdvertisingCardsCarousel } from 'presentation/components/carousel/advertising-cards-carousel';
import { FetchEmpty, FetchEmptyPaddingSize } from 'presentation/components/fetch-state/empty';
import { FetchError } from 'presentation/components/fetch-state/error';
import { Header } from 'presentation/components/header';
import { Loader } from 'presentation/components/loader';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import { FetchState } from 'presentation/view-model/fetch-state';
import React, { useEffect, useState } from 'react';
import { isNamespaceProduction } from 'utils/env';
import { emptyList } from 'utils/index';

import styles from './styles.pcss';

const ADVERTISING_CARDS = List.of(
  new AdvertisementCard(
    '직장인 추천 카드!',
    new CardSummary(
      2928,
      '삼성카드 taptap O',
      '삼성카드',
      'https://cdn.banksalad.com/cards/samsung_2928.png'
    ),
    '2019년 뱅크샐러드 인기카드 1위',
    '(2019.1월~10월 발급수 기준)',
    emptyList(),
    'https://www.samsungcard.com/personal/card/simple-apply/UHPPCA0225M0.jsp?code=AAP1483&alncmpC=CYBERBR&affcode=BANKSALAD1408&cmpid=banksalad',
    'https://www.samsungcard.com/personal/card/simple-apply/UHPPCA0225M0.jsp?code=AAP1483&alncmpC=CYBERBR&affcode=QFBANKTEST04&cmpid=banksalad',
    1,
    true
  ),
);

interface State {
    fetchState: FetchState;
    cardComparisons: List<ContentCardComparisonSummary>;
    featuredCardComparisons: List<ContentCardComparisonSummary>;
}

export const CardsThemesView = () => {
  const [{ fetchState, cardComparisons, featuredCardComparisons }, setState] = useState({
    fetchState: FetchState.FETCHING,
    cardComparisons: emptyList(),
    featuredCardComparisons: emptyList()
  });

  const renderAdvertisement = () => (
    <section className={ styles.heroWrap }>
      <div className={ styles.hero }>
        <div className={ styles.container }>
          <h2 className={ styles.heroPreTitle }>매력넘치는 혜택들로 똘똘 뭉쳤다!</h2>
          <h1 className={ styles.heroTitle }>톡톡튀는 신상카드</h1>
          <div className={ styles.carousel }>
            <AdvertisingCardsCarousel cards={ ADVERTISING_CARDS } />
          </div>
        </div>
      </div>
    </section>
  );

  const renderFeaturedThemeSection = (contents: List<ContentCardComparisonSummary>) => {
    return (
      <div className={ styles.container }>
        <h2 className={ styles.featuredTag }>테마별</h2>
        <h1 className={ styles.featuredTitle }>취향저격 카드추천</h1>
        <h3 className={ styles.featuredSubTitle }>뱅크샐러드의 금융전문가들이 엄선한 당신의 취향을 저격하는 카드를 소개합니다!</h3>
        <ul className={ styles.featuredList }>
          {
            !contents.isEmpty() ?
              contents.map(renderFeaturedTheme) :
              <FetchEmpty padding={ FetchEmptyPaddingSize.SMALL }/>
          }
        </ul>
      </div>
    );
  };

  const renderTotalThemeSection = (contents: List<ContentCardComparisonSummary>) => {
    const sortedThemes: List<ContentCardComparisonSummary> = List(contents.sort((t1, t2) => {
      if (!t1.hot && t2.hot) return 1;
      if (t1.hot && !t2.hot) return -1;

      return 0;
    }));

    return(
      <div className={ styles.container }>
        <span><b>{ `${ sortedThemes.size }개` }</b>의 컨텐츠</span>
        <ul>
          {
            !sortedThemes.isEmpty() ?
              sortedThemes.map(renderTheme) :
              <FetchEmpty padding={ FetchEmptyPaddingSize.SMALL } />
          }
        </ul>
      </div>
    );
  };

  const renderFeaturedTheme = (theme: ContentCardComparisonSummary, index: number) => (
    <li key={ `featured-theme-${index}` }>
      <a
        href={ BANKSALAD_SITEMAP_BY_ID.CARD_THEME(theme.id)}
        onClick={ onClickFeaturedTheme(theme) }
      >
        <div
          className={ styles.themeWithImage }
          style={ {
            float: index % 2 == 0 ? 'right' : 'left',
            backgroundImage: `url(${theme.thumbnailImageUrl})`
          } }
        >
          <span>{ theme.label }</span>
        </div>
        <div className={ styles.themeWithTitle } style={ { float: index % 2 == 0 ? 'left' : 'right' } }>
          <h5>{ theme.description }</h5>
          <h4>{ theme.title }</h4>
        </div>
      </a>
    </li>
  );

  const renderTheme = (theme: ContentCardComparisonSummary, index: number) => (
    <li key={ `theme-${index}` } className={ theme.hot ? styles.hotTheme : '' }>
      <a
        href={ BANKSALAD_SITEMAP_BY_ID.CARD_THEME(theme.id) }
        onClick={ onClickTheme(theme) }
      >
        <div
          style={{ backgroundImage: `url('${theme.thumbnailImageUrl}')` }}
          className={ styles.thumbnailImage }
        />
        <div className={ styles.themeDescription }>
          <h2>{ theme.description }</h2>
          <h1>{ theme.title }</h1>
        </div>
      </a>
    </li>
  );

  const onClickFeaturedTheme = (theme: ContentCardComparisonSummary) => () => {
    sendGAEvent(
      GA_DOMAIN.CARD,
      GA_CATEGORY.CARDS_THEMES.FEATURED_LIST,
      GA_ACTION.LINK.CARDS_THEMES_DETAIL,
      `${theme.title}_${theme.id}`
    );
  };

  const onClickTheme = (theme: ContentCardComparisonSummary) => () => {
    sendGAEvent(
      GA_DOMAIN.CARD,
      GA_CATEGORY.CARDS_THEMES.RESULT_LIST,
      GA_ACTION.LINK.CARDS_THEMES_DETAIL,
      `${theme.title}_${theme.id}`
    );
  };

  useEffect(() => {
    ADVERTISING_CARDS.map((card: AdvertisementCard) =>
      sendGAEvent(
        GA_DOMAIN.CARD,
        GA_CATEGORY.CARDS_THEMES.HERO,
        GA_ACTION.PRODUCT_IMPRESSION,
        `${card.card.companyName}_${card.card.id}_${card.order}`
      )
    );
  }, []);

  useEffect(() => {
    (async () => {
      const CARD_COMPARISON_CONTENTS_API_PATH = isNamespaceProduction() 
        ? `https://cdn.banksalad.com/app/banksalad-web/legacy-apis/card-comparison-contents.json` 
        : `https://cdn.staging.banksalad.com/app/banksalad-web/legacy-apis/card-comparison-contents.json`;

      const FEATURED_CARD_COMPARISON_CONTENTS_API_PATH = isNamespaceProduction() 
        ? `https://cdn.banksalad.com/app/banksalad-web/legacy-apis/featured-card-comparison-contents.json` 
        : `https://cdn.staging.banksalad.com/app/banksalad-web/legacy-apis/featured-card-comparison-contents.json`;
      
      const [cardComparisons, featuredCardComparisons] = await Promise.all([
        Axios.get(CARD_COMPARISON_CONTENTS_API_PATH),
        Axios.get(FEATURED_CARD_COMPARISON_CONTENTS_API_PATH),
      ]);

      setState({
        cardComparisons: List(cardComparisons.data.data.map((content: any) => 
          new ContentSummaryMapper().fromJson(content)
        )),
        featuredCardComparisons: List(featuredCardComparisons.data.data.map((content: any) =>
          new ContentSummaryMapper().fromJson(content)
        )),
        fetchState: FetchState.FETCHED,
      });
    })();
  }, []);

  return (
    <>
      <Header active={ FinanceSectorID.CARD } />
      {
        fetchState === FetchState.FETCHED ? (
          <div className={ styles.wrap }>
            { renderAdvertisement() }
            <section className={ styles.featuredThemes }>
              { renderFeaturedThemeSection(featuredCardComparisons) }
            </section>
            <section className={ styles.totalThemes }>
              { renderTotalThemeSection(cardComparisons) }
            </section>
          </div>
        ) : fetchState === FetchState.FETCHING ? (
          <Loader padding={ 350 } radius={ 25 } />
        ) : (
          <FetchError padding={ 350 }>
            데이터를 불러오는 중 에러가 발생했습니다<br />
            잠시 후 다시 시도해주세요
          </FetchError>
        )
      }
    </>
  );
};
