import React from 'react';
import { List } from 'immutable';
import { Link } from 'react-router-dom';

import { CardNonTemporalComputationResult } from 'core/entity/card/non-temporal-computation-result';
import { Card } from 'core/entity/card';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import { lets } from 'utils/index';
import { CardBenefitType } from 'core/entity/card/benefit-type';
import { isForDiscount, toCurrency } from 'presentation/module/sugar';
import { CardIssueUrlAlert } from 'presentation/components/alert/card-issue-url';
import { CardIssuePromotionAlert } from 'presentation/components/flexible-alert/card-issue-promotion';
import { GA_ACTION, GA_DOMAIN, GA_EVENT_VALUE, GA_LABEL, sendGAEvent } from 'presentation/module/analytics/ga';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';
import { OptimizeVariantType } from 'presentation/module/optimize-variant-type';

import styles from './styles.pcss';

interface Props {
    card: CardNonTemporalComputationResult,
    rank: number,
    benefitTypes: List<CardBenefitType>,
    activeSpecialServices: List<string>,
    gaCategory: string
}

interface State {
    alert: boolean
}

export class CardProfitsItem extends React.Component<Props, State> {
    state = {
        alert: false
    };

    private imageWrap: HTMLDivElement;

    render() {
        const { benefitTypes, activeSpecialServices, gaCategory } = this.props;
        const { alert } = this.state;
        const { card, summary } = this.props.card;
        const forDiscount = isForDiscount(benefitTypes);
        const totalBenefit = (
            forDiscount ?
                summary.discount + summary.point - Math.trunc(card.annualCostWithPromotion / 12) :
                summary.mileage
        );

        return (
            <div>
                <section className={ styles.head }>
                    <img src={ card.companyImageUrl } className={ styles.ci } />
                    <span className={ styles.type }>{ this.toCardTypeString(card.type) }</span>
                    <h4 className={ styles.name }>{ card.name }</h4>
                    <ul className={ styles.flags }>
                        { card.isInPromotion() && <li>연회비<br/>이벤트</li> }
                    </ul>
                </section>
                <section className={ styles.body }>
                    <a
                        href={ BANKSALAD_SITEMAP_BY_ID.CARD_PROFITS(card.id) }
                        className={ styles.link }
                        onClick={ () => sendGAEvent(GA_DOMAIN.CARD, gaCategory, GA_ACTION.LINK.CARDS_DETAIL_PROFITS, `카드이미지_${card.companyName}_${card.id}`) }
                    >
                        <div
                            ref={ node => this.imageWrap = node }
                            className={ styles.defaultImage }
                        >
                            <img
                                src={ card.imageUrl }
                                onLoad={ this.onLoadImage }
                            />
                        </div>
                        { this.renderRank() }
                    </a>
                    <dl className={ styles.benefits }>
                        {
                            forDiscount ?
                                <>
                                <dt>할인</dt>
                                <dd>{ `${toCurrency(summary.discount)}원` }</dd>
                                <dt>적립</dt>
                                <dd>{ `${toCurrency(summary.point)}점` }</dd>
                                </> :
                                <>
                                <dt>마일리지</dt>
                                <dd>{ `${toCurrency(summary.mileage)}마일` }</dd>
                                </>
                        }
                        <dt>연회비</dt>
                        <dd>{ `${toCurrency(card.annualCostWithPromotion)}원` }</dd>
                        { card.isInPromotion() && this.renderPromotionBenefit() }
                        { this.renderMonthlyBenefitDescription(forDiscount, totalBenefit) }
                    </dl>
                    <div className={ styles.buttons }>
                        <a
                            href={ BANKSALAD_SITEMAP_BY_ID.CARD_PROFITS(card.id) }
                            onClick={ () => sendGAEvent(
                                GA_DOMAIN.CARD,
                                gaCategory,
                                GA_ACTION.LINK.CARDS_DETAIL_PROFITS,
                                GA_LABEL.PRODUCT_DETAIL('상세보기', card.companyName, card.id)
                            ) }
                            className={ `${styles.button} ${styles.filled}` }
                        >
                            상세보기
                        </a>
                    </div>
                </section>
                {
                    !card.specialBenefitCategories.isEmpty() && (
                        <section className={ styles.services }>
                            <h5 className={ styles.title }>특별혜택</h5>
                            {
                                card.specialBenefitCategories.map(service =>
                                    lets(activeSpecialServices.contains(service), active =>
                                        <span
                                            key={ `card-${card.id}-service-${service}` }
                                            className={ `${styles.service} ${active ? styles.active : null}` }
                                        >
                                            { service }
                                        </span>
                                    )
                                )
                            }
                        </section>
                    )
                }
                { alert && this.renderAlert() }
            </div>
        );
    }

    private renderAlert = () => {
        const { gaCategory } = this.props;
        const { card } = this.props.card;

        return card.annualCostPromotion ?
            <CardIssuePromotionAlert
                cardId={ card.id }
                description={ card.annualCostPromotion.description }
                summary={ card.annualCostPromotion.summary }
                companyName={ card.companyName }
                issueUrlDesktop={ card.issueUrlDesktop }
                issueUrlMobile={ card.issueUrlMobile }
                onCancel={ () => this.setState({ alert: false }) }
            /> :
            <CardIssueUrlAlert
                cardId={ card.id }
                issueUrlDesktop={ card.issueUrlDesktop }
                issueUrlMobile={ card.issueUrlMobile }
                companyName={ card.companyName }
                companyImageUrl={ card.companyImageUrl }
                gaCategory={ gaCategory }
                onCancel={ () => this.setState({ alert: false }) }
            />
    };

    private renderRank = () => {
        const { rank } = this.props;
        const rankStyle = lets(rank, it => {
            switch (it) {
                case 1:
                    return styles.firstRank;
                case 2:
                    return styles.secondRank;
                case 3:
                    return styles.thirdRank;
                default:
                    return styles.rank;
            }
        });

        return (
            <div className={ rankStyle }>{ rank }</div>
        );
    };

    private renderMonthlyBenefitDescription = (forDiscount: boolean, totalBenefit: number) =>
        lets(window.variantType, it => {
            const unit = forDiscount ? '원' : '마일';

            switch (it) {
                case OptimizeVariantType.VARIANT_B:
                    const description =
                        <span>
                            매월&nbsp;
                            <strong>{ toCurrency(totalBenefit) }</strong>
                            { unit }
                            이 절약되네요!
                        </span>;
                    return (
                        <dd className={ styles.variantBResult }>{ description }</dd>
                    );
                default:
                    return (
                        <>
                        <dt className={ styles.result }>월 혜택</dt>
                        <dd className={ styles.result }>
                            { toCurrency(totalBenefit) }
                            { unit }
                        </dd>
                        </>
                    );
            }
        });

    private renderPromotionBenefit = () => lets(window.variantType, it => {
        const { card } = this.props.card;

        switch (it) {
            case OptimizeVariantType.VARIANT_A:
            case OptimizeVariantType.VARIANT_B:
                return (
                    <>
                    <dt className={ styles.variantABPromotionTitle }>연회비 이벤트가</dt>
                    <dd className={ styles.variantABPromotionData }>{ `${toCurrency(card.annualCosts.first().price)}원` }</dd>
                    <dd className={ styles.variantABPromotionDescription }>
                        <strong>100%</strong>
                        <span>연회비 캐시백</span>
                    </dd>
                    </>
                );
            default:
                return (
                    <>
                    <dt className={ styles.event }>이벤트</dt>
                    <dd className={ styles.event }>{ card.annualCostPromotion.summary }</dd>
                    </>
                )
        }
    });

    private toCardTypeString = (type: Card.Type) => {
        switch (type) {
            case Card.Type.CREDIT:
                return '신용';
            case Card.Type.CHECK:
                return '체크';
            case Card.Type.HYBRID:
                return '하이브리드';
            default:
                return '';
        }
    };

    private onLoadImage = (e: React.ChangeEvent<HTMLImageElement>) => {
        const element = e.target;

        this.imageWrap.className = element.height <= element.width ? styles.landscapeImage : styles.portraitImage;
    };
}
