import { CardDetailRoute } from 'presentation/routes/cards/card';
import { CardDetailOnlineIssue } from 'presentation/routes/cards/card-online-issue';
import { CardDetailProfitsRoute } from 'presentation/routes/cards/card-profits';
import { CardsEvent } from 'presentation/routes/cards/event';
import { CardsEvents } from 'presentation/routes/cards/events';
import { CardsProfits } from 'presentation/routes/cards/profits';
import { CardsPromotionAnnualFee } from 'presentation/routes/cards/promotion-annual-fee';
import { CardsQuestions } from 'presentation/routes/cards/questions';
import { CardsThemeRoute } from 'presentation/routes/cards/theme';
import { CardsThemesRoute } from 'presentation/routes/cards/themes';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

export const CardsRoute = ({ match }: RouteComponentProps<any>) => (
  <div>
    <Switch>
      <Route path={ `${match.url}/questions` } component={ CardsQuestions } />
      <Route path={ `${match.url}/profits` } component={ CardsProfits } />
      <Route path={ `${match.url}/promotion/annual-fee` } component={ CardsPromotionAnnualFee } />
      <Route path={ `${match.url}/events/:id` } component={ CardsEvent } />
      <Route path={ `${match.url}/events` } component={ CardsEvents } />
      <Route path={ `${match.url}/themes/:id` } component={ CardsThemeRoute } />
      <Route path={ `${match.url}/themes` } component={ CardsThemesRoute } />
      <Route path={ `${match.url}/:id/issue` } component={ CardDetailOnlineIssue } />
      <Route path={ `${match.url}/:id/profits` } component={ CardDetailProfitsRoute } />
      <Route path={ `${match.url}/:id` } component={ CardDetailRoute } />
    </Switch>
  </div>
);
